import { createEffect, createStore } from "effector";
import { getTableInfo } from "../pages/tablePage/helpers";

export type Sketch = {
    id: string,
    date: string,
};

export const $sketches = createStore<Array<Sketch>>([]);

export const getTableInfoFx = createEffect(getTableInfo);

$sketches
    .on(getTableInfoFx.doneData, (_, { items }) => items);
