import { useEffect, useState } from "react";
import { headerTittles } from "../../config";
import TableLine from "../tableLine/TableLine";
import Pagination from "../../../../components/pagination/Pagination";
import { InfoType } from "../../types";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getPageCount } from "../../../../store/slices/pagination";
import { getTableInfoFx } from "../../../../entities/sketches";
import './style.css'


export default function Table() {
    const dispatch = useAppDispatch();
    const [info, setInfo] = useState<InfoType[]>([]);
    const page = useAppSelector((state) => state.paginationPages.currentPage);

    useEffect(() =>
        void getTableInfoFx(page).then(result => {
            const { pagesCount, items } = result;
            dispatch(getPageCount(pagesCount));
            setInfo(items);
        })
        , [page, dispatch]);

    return (
        <div className="table-wrapper">
            <div className="table">
                <div className="rectangle"></div>
                <div className="table-header">
                    {headerTittles.map((item, index) => (
                        <div className="table-header-item" key={index}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                <path d="M3.25 19.5V17.3333H9.75V19.5H3.25ZM3.25 14.0833V11.9167H16.25V14.0833H3.25ZM3.25 8.66667V6.5H22.75V8.66667H3.25Z" fill="#D6D6D6" />
                            </svg>
                            <p>{item}</p>
                        </div>
                    ))}
                </div>
                {info.map((item) => (
                    <TableLine key={item.id} item={item} />
                ))}
            </div>
            <Pagination />
        </div>
    )
}
