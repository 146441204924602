import { config } from "../../../config";
import { ResultData } from "../types";

export const sendRequest = async (url: string) => {
    try {
        let response = await fetch(url);
        if (!response.ok) throw new Error("HTTP error: " + response.status);
        let data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        return { pagesCount: 0, itemsArray: [] };
    }
}

export async function getTableInfo(page: number): Promise<ResultData> {
    const res = await fetch(`${config.backendUrl}/sketches?page=${page}`);

    if (!res.ok) throw new Error('Bad request.');

    const data = await res.json();
    return data;
}