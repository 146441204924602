import Header from './components/header/Header';
import Table from './components/table/Table';
import './style.css';

export default function TablePage() {
    return (
        <div className="wrapper">
            <Header />
            <Table />
        </div>
    );
}
