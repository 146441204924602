import { configureStore } from '@reduxjs/toolkit'
import searchReducer from './slices/search'
import paginationReducer from './slices/pagination'

export const store = configureStore({
    reducer:{
        search: searchReducer,
        paginationPages: paginationReducer,
    }
})

export type Store = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch
export default store