import { useParams } from "react-router-dom";
import { config } from "../../config";
import './style.css'


export default function DrawingPage() {
    const { id } = useParams();

    return (
        <div className="plan-wrapper">
            <div className="sketch-wrapper">
                <div className="box">
                    <p>Uploaded sketch</p>
                    <img src={`${config.AIUrl}/drawing/${id}.png`} alt="plan" className="image" />
                </div>
                <div className="box">
                    <p>Segmented sketch</p>
                    <img src={`${config.backendUrl}/sketches/${id}.png`} alt="plan" className="image" />
                </div>
            </div>
            <p className="title">Uploaded segments</p>
{/*             {planData?.objects && planData.objects.length > 0 && (
                <ObjectsTable data={planData.objects} />
            )} */}
        </div>
    )
}
