import ReactPaginate from "react-paginate";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { getCurrentPage } from "../../store/slices/pagination";
import './style.css'


export default function Pagination() {
    const dispatch = useAppDispatch()

    const pageCount = useAppSelector((state) => state.paginationPages.pageCount)
    const handlePageClick = (event: { selected: number }) => {
        dispatch(getCurrentPage(event.selected + 1))
    }
    return (
        <ReactPaginate
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="pagination__link--active"
            previousLinkClassName="pagination__link"
            nextLinkClassName="pagination__link"
            breakLabel="..."
            nextLabel=">"
            previousLabel="<"
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
        />
    )
}
