import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TablePage from './pages/tablePage';
import DrawingPage from './pages/drawingPage';
import './App.css';


export default function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<TablePage />} />
        <Route path='/plan/:id' element={<DrawingPage />} />
      </Routes>
    </Router>
  );
}
