import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Store } from '../index';

interface Pages {
    pageCount: number,
    currentPage: number
}

const initialState: Pages = {
    pageCount: 0,
    currentPage: 0,
};

export const paginationSlice = createSlice({
    name: 'paginationPages',
    initialState,
    reducers: {
        getPageCount: (state, action: PayloadAction<number>) => {
            state.pageCount = action.payload;
        },
        getCurrentPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload;
        },
    }
});

export const { getPageCount, getCurrentPage } = paginationSlice.actions;
export const currentPage = (state: Store) => state.paginationPages.currentPage;
export const pageCount = (state: Store) => state.paginationPages.pageCount;
export default paginationSlice.reducer;