import React, { useEffect, useState } from "react";
import './style.css'
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getSearchValue } from "../../../../store/slices/search";

function Header() {

    const dispatch = useAppDispatch();
    const [searchValue, setSearchValue] = useState('');
    const searchRequest = useAppSelector((state) => state.search.value)


    useEffect(() => {
        console.log(searchRequest);
    }, [searchRequest])

    function handleKeyPress(event: any) {
        if (event.key === 'Enter') {
            dispatch(getSearchValue(searchValue));
        }
    }
    return (
        <div className="header-wrapper">
            <div className="header-box">
                <div className="input-wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                        <path d="M17.846 15.2048L14.0644 11.4206C13.3358 10.6914 12.1539 10.6912 11.425 11.4201C10.6962 12.1488 10.6962 13.3303 11.425 14.0591L15.2102 17.8443C15.6291 18.2632 16.3186 18.2632 16.7375 17.8443L17.846 16.7359C18.2649 16.3133 18.2649 15.62 17.846 15.2048Z" fill="#D6D6D6" />
                        <path d="M9.52926 10.9101C9.9111 11.292 10.5302 11.292 10.912 10.9101C11.2939 10.5283 11.2939 9.90922 10.912 9.52738L10.7451 9.36049C10.3299 8.94527 10.2859 8.29699 10.5262 7.76119C11.4345 5.7359 11.0599 3.27304 9.39765 1.6108C7.25122 -0.535631 3.76281 -0.535631 1.61268 1.6108C-0.537459 3.75723 -0.533752 7.24564 1.61268 9.39577C3.27492 11.058 5.73777 11.4326 7.76307 10.5243C8.29887 10.284 8.94715 10.328 9.36237 10.7433L9.52926 10.9101ZM2.68033 8.31329C1.12334 6.7563 1.12334 4.23174 2.68033 2.67845C4.23733 1.12146 6.76188 1.12146 8.31517 2.67845C9.87217 4.23545 9.87217 6.76 8.31517 8.31329C6.76188 9.87029 4.23733 9.87029 2.68033 8.31329Z" fill="#D6D6D6" />
                    </svg>
                    <input type="text"
                        placeholder="Search"
                        value={searchValue}
                        onChange={e => setSearchValue(e.target.value)}
                        onKeyDown={handleKeyPress}
                    ></input>
                </div>
            </div>
        </div>
    )
}

export default Header