import { InfoType } from '../../types';
import { Link } from 'react-router-dom';
import './style.css'


type TableLineProps = {
    item: InfoType,
};

export default function TableLine({ item }: TableLineProps) {
    return (
        <Link to={`/plan/${item.id}`} className="table-line">
            <div className="table-cell">
                <p>item.name</p>
            </div>
            <div className="table-cell">
                <p>item.project</p>
            </div>
            <div className="table-cell">
                <p>item.email</p>
            </div>
            <div className="table-cell">
                <p>item.social</p>
            </div>
        </Link>
    )
}
