import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Store } from '../index'

interface SearchState {
    value: string
}

const initialState: SearchState = {
    value: ' '
}
export const searchSlice = createSlice({
    name: 'searh',
    initialState,
    reducers: {
        getSearchValue: (state, action: PayloadAction<string>) => {
            state.value = action.payload;
        }
    }
})

export const { getSearchValue } = searchSlice.actions;
export const receivedValue = (state: Store) => state.search.value
export default searchSlice.reducer